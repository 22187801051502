<template>
  <div class="PropagandaEducation">

    <div class="form">
      <el-form ref="form" :inline="true" label-width="100px" :model="form" @submit.native.prevent >
        <el-form-item label="医生姓名" prop="docId" >
          <el-select v-model="form.docId" clearable placeholder="请选择">
            <template v-for="(item,index) in doctorInfoList" >
              <el-option :label="item.docName" :value="item.id" :key="index" ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="宣教内容名称" prop="educationContentName" >
          <el-input v-model="form.educationContentName" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" native-type="submit" >查询</el-button>
        </el-form-item>
      </el-form>
    </div>


    <div class="list">
      <div class="TestKitList-list-handle">
        <el-button type="primary" @click="addFn" size="small" >新建宣教内容</el-button>
      </div>
      <el-table
          :data="table"
          v-loading="tableLoad"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%">
        <el-table-column
            prop=""
            align="center"
            label="宣教内容名称">
          <template slot-scope="scope">
            <span class="nameClass" @click="detailFn(scope.row)" >{{ scope.row.educationContentName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="contentType"
            align="center"
            label="内容类型">
          <template #default="scope" >
            <span>{{ contentTypeText(scope.row.contentType) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="docId"
            align="center"
            label="所属医生">
          <template #default="scope" >
            <span>{{ docIdText(scope.row.docId) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="videoClassificationInfoId"
            align="center"
            label="所属分类">
          <template #default="scope" >
            <span>{{ videoClassificationInfoIdText(scope.row.videoClassificationInfoId) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="subordinateUnit"
            align="center"
            label="所属单位">
        </el-table-column>
        <el-table-column
            prop="introduction"
            align="center"
            label="视频简介">
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-popconfirm
                title="确定删除该宣教吗？"
                @confirm="delFn(scope.row)"
            >
              <el-button type="text" size="small" style="color: #f56c6c;" slot="reference">删除</el-button>
            </el-popconfirm>
            <template v-if="scope.row.state === '0'">
              <el-button type="text" size="small" style="margin-left: 10px;" @click="stateFn(scope.row.educationContentId,'1')" >下架</el-button>
            </template>
            <template v-else-if="scope.row.state === '1'" >
              <el-button type="text" size="small" style="margin-left: 10px;" @click="stateFn(scope.row.educationContentId,'0')" >上架</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <Add v-model="is_add" :params.sync="add_params" @upDateFn="getList" @cancel="add_params = {}" />

  </div>
</template>

<script>
import Add from "@/components/PropagandaEducation/PropagandaEducation/components/Add";
export default {
  name: "index",
  components: {
    Add
  },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        docId: "",
        educationContentName: "",
      },
      table: [],
      tableLoad: false,
      total: 0,

      is_add: false,
      add_params: {},
      doctorInfoList: [],
      educationContentDict: [],
      videoClassificationInfo: [],
    }
  },
  computed: {
    contentTypeText() {
      return function (e) {
        let str = "",
            arr = (this.educationContentDict || []).filter(item => item.dictKey === e);
        if (arr.length) {
          str = arr[0].dictValue;
        }
        return str;
      }
    },
    docIdText() {
      return function (e) {
        let str = "",
            arr = (this.doctorInfoList || []).filter(item => item.id === e);
        if (arr.length) {
          str = arr[0].docName;
        }
        return str;
      }
    },
    videoClassificationInfoIdText() {
      return function (e) {
        let str = "",
            arr = (this.videoClassificationInfo || []).filter(item => item.videoClassificationInfoId === e);
        if (arr.length) {
          str = arr[0].classificationName;
        }
        return str;
      }
    },
  },
  created() {
    this.getDoctorInfoList();
    this.getEducationContentDict();
    this.getVideoClassificationInfo();
    this.getList();
  },
  mounted() {

  },
  methods: {
    delFn(e) {
      this.$get("ec/editeducationconten",{
        educationContentId: e.educationContentId
      })
        .then(res => {
          if (!res) return false;
          this.form.pageNum = 1;
          this.getList();
        })
    },
    stateFn(id,state) {
      this.$post("ec/editeducationcontenstate",{
        educationContentId: id,
        state: state,
      })
          .then(res => {
            if (!res) return false;
            this.getList();
          })
    },
    getList() {
      this.$post("ec/getEducationContentByPage",this.form)
        .then(res => {
          let data = res.data;
          this.total = data.total;
          this.table = data.list;
        })
    },
    getDoctorInfoList() {
      // 所属医生
      this.$get("ec/getdoctorinfolist")
          .then(res => {
            if(!res) return false;
            let data = res.data;
            console.log(data);
            this.doctorInfoList = data;
          })
    },
    getEducationContentDict() {
      // 内容类型
      this.$get("ec/geteducationcontentdict")
          .then(res => {
            if (!res) return false;
            let data = res.data;
            console.log(data)
            this.educationContentDict = data;
          })
    },
    getVideoClassificationInfo() {
      // 所属分类
      this.$get("ec/getvideoclassificationinfo")
          .then(res => {
            if (!res) return false;
            let data = res.data;
            console.log(data)
            this.videoClassificationInfo = data;
          })
    },
    addFn() {
      this.is_add = true;
    },
    detailFn(row) {
      console.log(row);
      this.is_add = true;
      this.$nextTick(() => {
        this.add_params = {
          ...row,
          video: row.videoUrl,
          image: row.coverUrl,
        };
      })
    },
    onSubmit() {
      this.form.pageNum = 1;
      this.getList();
    },
    handleSizeChange(e) {
      this.form.pageNum = 1;
      this.form.pageSize= e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.nameClass{
  color: #2090ff;
  cursor: pointer;
}
</style>
